<script setup>
const props = defineProps({
  withPrint: Boolean,
  useEmbedState: Boolean
})

const backendStore = useBackendStore();
const { isEmbed } = backendStore;
</script>

<template>
  <div class="group/columns lg:grid" :class="[{'with-print':withPrint, 'grid-cols-3 xl:grid-cols-4': !isEmbed || !useEmbedState, 'grid-cols-2':isEmbed && useEmbedState}]">
    <slot />
  </div>
</template>
